.Group-form {
  margin-top: 25px;
}

.Group-form__name {
  font-size: 13px;
  font-weight: 500;
  letter-spacing: -0.1px;
  color: #000000;
  margin-bottom: 13px;
}

.Group-form .Form-input {
  margin-top: 0;
}

.Group-form__radio {
  display: flex;
  flex-wrap: wrap;
}

.Group-form__radio.flex-direction-column > div + div {
  margin: 15px 0 0;
}

.Group-form__input {
  display: flex;
}

.Group-form__input-list {
  display: flex;
  flex-direction: row;
}

.Group-form__input-list .Form-input + .Form-input {
  margin-left: 10px;
}

.Group-form__input-calendar {
  align-items: flex-end;
}

.Group-form__input-calendar .react-datepicker-wrapper, .Group-form__input-calendar .react-datepicker__input-container, .Group-form__input-calendar input[type='text'] {
  width: 100%;
}

.Group-form__input-calendar > span {
  line-height: 30px;
  margin: 0 15px;
  font-size: 13px;
  color: #828282;
}

.Group-form__input-calendar .time-separator {
  margin: 0 10px;
}

.Group-form__input-calendar .calendar-date {
  flex: auto;
  width: 100%;
  position: relative;
}

.Group-form__input-calendar .calendar-date:after {
  content: '';
  background-image: url("../img/icons/calendar.svg");
  width: 14px;
  height: 15px;
  position: absolute;
  right: 8px;
  top: 7px;
  pointer-events: none;
}

.Group-form__input-calendar .calendar-time {
  width: 63px;
  min-width: 63px;
}

.Group-form .date-error {
  display: none;
  font-size: 12.5px;
  letter-spacing: -0.08px;
  color: #e64646;
  margin-top: 9px;
}

.Group-form .show-error input[type='text'], .Group-form .show-error .Custom-select__input-block_text {
  border: solid 0.5px #ff5c5c;
  background-color: #faebeb;
}

.Group-form .show-error .date-error {
  display: block;
}
