.Group-form {
  margin-top: 25px;
}

.Group-form__name {
  font-size: 13px;
  font-weight: 500;
  letter-spacing: -0.1px;
  color: #000000;
  margin-bottom: 13px;
}

.Group-form__radio {
  display: flex;
  flex-wrap: wrap;
}

.Form-input {
  margin-top: 25px;
}

.Form-input__name {
  margin-bottom: 10px;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: -0.1px;
  color: #000000;
}

.Form-input__name span {
  color: #828282;
}

.Form-input input {
  width: 100%;
  padding: 6px 9px;
  border-radius: 4px;
  line-height: 18px;
  height: 30px;
  border: solid 1px #d3d9de;
  background-color: #ffffff;
  outline: none;
  font-size: 13px;
  box-sizing: border-box;
}

.Form-input input::placeholder {
  font-size: 13px;
  line-height: 1.46;
  letter-spacing: -0.1px;
  color: #828282;
}

.Form-input__hint {
  margin-top: 10px;
  font-size: 12.5px;
  letter-spacing: -0.1px;
  color: #828282;
}

.Form-checkbox {
  position: relative;
  margin-top: 25px;
  display: flex;
  align-items: center;
}

.Form-checkbox input[type="checkbox"] {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
  outline: 0 !important;
}

.Form-checkbox input[type="checkbox"]:hover + label {
  color: rgba(0, 0, 0, 0.8);
}

.Form-checkbox input[type="checkbox"] + label {
  cursor: pointer;
  position: relative;
  padding-left: 22px;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.46;
  letter-spacing: -0.1px;
  color: #000000;
}

.Form-checkbox input[type="checkbox"] + label:before {
  content: '';
  width: 15px;
  height: 15px;
  border-radius: 3px;
  border: solid 1px #c1c9d1;
  background-color: #ffffff;
  display: inline-block;
  position: absolute;
  top: 1px;
  left: 0;
  box-sizing: border-box;
}

.Form-checkbox input[type="checkbox"] + label:after {
  content: "";
  color: #2c7be5;
  background: url("../img/icons/checked.svg");
  position: absolute;
  top: 1px;
  left: 0;
  font-size: 16px;
  opacity: 0;
  transform: scale(0);
  width: 15px;
  height: 15px;
  box-sizing: border-box;
}

.Form-checkbox input[type="checkbox"]:checked + label:after {
  opacity: 1;
  transform: scale(1);
}

.Form-radio {
  position: relative;
  display: flex;
}

.Form-radio + .Form-radio {
  margin-left: 19px;
}

.Form-radio input[type="radio"] {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
  outline: 0 !important;
}

.Form-radio input[type="radio"]:hover + label:before {
  background-color: #f2f4f8;
}

.Form-radio input[type="radio"] + label {
  cursor: pointer;
  position: relative;
  padding-left: 23px;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.46;
  letter-spacing: -0.1px;
  color: #000000;
}

.Form-radio input[type="radio"] + label:before {
  content: '';
  width: 15px;
  height: 15px;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  left: 0;
  top: 1px;
  border: solid 1px #c1c9d1;
  background-color: #ffffff;
  box-sizing: border-box;
}

.Form-radio input[type="radio"] + label:after {
  content: '';
  background: #5181b8;
  position: absolute;
  top: 5px;
  left: 4px;
  opacity: 0;
  transform: scale(0);
  width: 7px;
  height: 7px;
  border-radius: 50%;
}

.Form-radio input[type="radio"]:checked + label:after {
  opacity: 1;
  transform: scale(1);
}

.Form-radio input[type="radio"]:checked + label:before {
  border-color: #5181b8;
}

.Form-radio-color {
  position: relative;
  display: flex;
  align-items: center;
}

.Form-radio-color + .Form-radio-color {
  margin-left: 0;
}

.Form-radio-color input[type="radio"] {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
  outline: 0 !important;
}

.Form-radio-color input[type="radio"]:hover + label {
  opacity: 0.85 !important;
}

.Form-radio-color input[type="radio"] + label {
  position: relative;
  width: 34px;
  height: 34px;
  pointer-events: none;
  cursor: pointer;
}

.Form-radio-color input[type="radio"] + label:before {
  content: '';
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border: 2px solid transparent;
  background-color: #fff;
  opacity: 0;
  box-sizing: border-box;
}

.Form-radio-color input[type="radio"] + label:after {
  content: '';
  position: absolute;
  top: 5px;
  left: 5px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  cursor: pointer;
  pointer-events: all;
}

.Form-radio-color input[type="radio"]:checked + label:before {
  opacity: 1;
}

.Form-radio-color input[type="radio"]:checked + label:before {
  border-color: #07F;
}

.Form-radio-color__option {
  position: absolute;
  width: 26px;
  height: 27px;
  background: url("../img/icons/colors.png") no-repeat;
  -webkit-background-size: contain;
  background-size: contain;
  border-radius: 50%;
  z-index: 1;
  left: 7px;
  top: 4px;
}

.Form-radio-color__option:hover {
  cursor: pointer;
  background: rgba(0, 0, 0, 0.5);
}

.Form-radio-color.color-black label:after {
  background: #000;
}

.Form-radio-color.color-blue label:after {
  background: #07F;
}

.Form-radio-color.color-transparent label:after {
  background: url("../img/icons/transparent.png");
  background-size: contain;
}

.Form-radio-color.color-gradient label:after {
  background: url("../img/icons/gradient.jpg") center;
  background-size: contain;
}

.Form-radio-color.color-photo label:after {
  background: url("../img/icons/photo.svg") center no-repeat;
  background-size: 14px 12px;
  border: 1px solid #5181b8;
  box-sizing: border-box;
}

.Form-radio-color_color-photo {
  position: relative;
  width: 26px;
  height: 26px;
  cursor: pointer;
  border-radius: 50%;
  background: url("../img/icons/photo.svg") center no-repeat;
  background-size: 14px 12px;
  border: 1px solid #5181b8;
  box-sizing: border-box;
  margin-left: 4px;
}

.Form-radio-color_color-photo:hover {
  opacity: .85;
}

.Form-radio-icon {
  position: relative;
}

.Form-radio-icon + .Form-radio-icon {
  margin-left: 10px;
}

.Form-radio-icon input[type="radio"] {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
  outline: 0 !important;
}

.Form-radio-icon input[type="radio"]:hover + label span {
  opacity: 0.85 !important;
}

.Form-radio-icon input[type="radio"] + label {
  position: relative;
  width: 65px;
  height: 65px;
  border-radius: 6px;
  border: 1px solid #e7e8ec;
  background-color: #fff;
  display: block;
  cursor: pointer;
  box-sizing: border-box;
}

.Form-radio-icon input[type="radio"] + label:before {
  content: '';
  width: 65px;
  height: 65px;
  border-radius: 6px;
  border: 2px solid #e7e8ec;
  display: inline-block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  box-sizing: border-box;
}

.Form-radio-icon input[type="radio"]:checked + label {
  border-color: transparent;
}

.Form-radio-icon input[type="radio"]:checked + label:before {
  opacity: 1;
  border-color: #5c9ce6;
}

.Form-radio-icon__img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 31px;
  height: 31px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Form-radio-icon__none {
  background: url("../img/icons/noicon.svg") no-repeat;
  -webkit-background-size: contain;
  background-size: contain;
}

.Form-radio-icon__vk {
  background: url("../img/icons/vk blue.svg") no-repeat;
  -webkit-background-size: contain;
  background-size: contain;
}

.Form-radio-icon__vk-black {
  background: url("../img/icons/vk black.svg") no-repeat;
  -webkit-background-size: contain;
  background-size: contain;
}

.Form-radio-icon__add {
  color: #6190ca;
}

.Form-radio-icon_div-upload-icon {
  position: relative;
  width: 65px;
  height: 65px;
  border-radius: 6px;
  display: block;
  cursor: pointer;
  box-sizing: border-box;
  border: 2px dashed #6190ca !important;
  background: url("../img/icons/upload.svg") no-repeat center;
  background-size: 20px;
  transform: rotateZ(180deg);
}

.Form-toggle {
  margin-top: 25px;
}

.Form-toggle input[type="checkbox"] {
  display: none;
}

.Form-toggle input[type="checkbox"] + label:before {
  content: '';
  display: block;
  height: 9px;
  width: 28px;
  border-radius: 45px;
  position: absolute;
  top: 3px;
  right: -40px;
  background: #ced7e0;
}

.Form-toggle input[type="checkbox"] + label:after {
  content: ' ';
  display: block;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: -25px;
  background: #fff;
  box-shadow: inset 0 0 0 1px #b4bfcc;
}

.Form-toggle input[type="checkbox"]:checked + label::after {
  right: -42px;
  background: #5181b8;
  box-shadow: none;
}

.Form-toggle input[type="checkbox"]:checked + label::before {
  background: #bccde0;
}

.Form-toggle label {
  position: relative;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: -0.1px;
  color: #000;
  cursor: pointer;
}

.Form-add-img {
  margin-top: 25px;
}

.Form-add-img__name {
  font-size: 13px;
  font-weight: 500;
  letter-spacing: -0.1px;
  color: #000000;
  margin-bottom: 10px;
}

.Form-add-img__box {
  display: flex;
  align-items: center;
}

.Form-add-img__img {
  width: 80px;
  height: 80px;
  border-radius: 4px;
  background: rgba(0, 28, 61, 0.08) url("../img/icons/camera.svg") no-repeat center;
  position: relative;
}

.Form-add-img__img--rounded {
  border-radius: 50%;
}

.Form-add-img__img_close {
  position: absolute;
  width: 16px;
  height: 16px;
  background: rgba(0, 0, 0, 0.7) url("../img/icons/close-small.svg") no-repeat center center;
  background-size: 8px;
  border-radius: 2.5px;
  right: 5px;
  top: 5px;
  cursor: pointer;
}

.Form-add-img__img_close:hover {
  background: rgba(0, 0, 0, 0.7) url("../img/icons/close-small-hover.svg") no-repeat center center;
  background-size: 8px;
}

.Form-add-img__info {
  margin-left: 15px;
}

.Form-add-img__text {
  font-size: 12.5px;
  letter-spacing: normal;
  color: #828282;
  margin-bottom: 10px;
}

.Form-textarea {
  margin-top: 25px;
}

.Form-textarea__name {
  font-size: 13px;
  font-weight: 500;
  letter-spacing: -0.1px;
  color: #000000;
}

.Form-textarea textarea {
  width: 100%;
  resize: none;
  font-size: 13px;
  margin-top: 9px;
  border-radius: 4px;
  border: 1px solid #d3d9de;
  background-color: #fff;
  padding: 5px 10px;
  letter-spacing: -0.1px;
  box-sizing: border-box;
  font-family: inherit;
  outline: none;
}

.Form-textarea textarea::placeholder {
  font-size: 13px;
  letter-spacing: -0.1px;
  color: #828282;
}
