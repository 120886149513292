.calendar-date .react-datepicker {
  font-size: 13px;
  border: none;
  border-radius: 6px;
}

.calendar-date .react-datepicker-wrapper {
  width: 100%;
}

.calendar-date .react-datepicker__input-container {
  width: 100%;
}

.calendar-date .react-datepicker-popper {
  left: auto !important;
  margin-top: 3px;
}

.calendar-date .react-datepicker__triangle {
  display: none;
}

.calendar-date .react-datepicker__month-container {
  min-width: 282px;
}

.calendar-date .react-datepicker__header {
  background-color: #5181b8;
  border: none;
}

.calendar-date .react-datepicker__navigation {
  border: none;
  width: 35.3px;
  height: 30px;
  top: 0;
  background: url(../img/icons/chevron_16_white.svg) center no-repeat;
  background-size: 9px 12px;
  outline: none;
  opacity: 0.8;
  transform: scaleX(0.85);
}

.calendar-date .react-datepicker__navigation:hover {
  opacity: 1;
}

.calendar-date .react-datepicker__navigation--previous {
  left: auto;
  right: 27px;
  transform: rotate(180deg) scaleX(0.85);
}

.calendar-date .react-datepicker__navigation--next {
  right: -3px;
}

.calendar-date .react-datepicker__current-month {
  color: #fff;
  font-size: 13px;
  text-align: left;
  margin-left: 10px;
  font-weight: normal;
  text-transform: capitalize;
}

.calendar-date .react-datepicker__day-names {
  margin-top: 9px;
  text-transform: capitalize;
}

.calendar-date .react-datepicker__day-name {
  color: #fff;
  opacity: .72;
  width: 40px;
  height: 40px;
  margin: 0;
  line-height: 45px;
}

.calendar-date .react-datepicker__month {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid #cfd9e1;
  border-top: none;
  margin: 0;
}

.calendar-date .react-datepicker__day {
  width: 40px;
  height: 40px;
  line-height: 40px;
  margin: 0;
}

.calendar-date .react-datepicker__day:hover {
  border-radius: 0;
  background-color: #f0f2f5;
}

.calendar-date .react-datepicker__day--outside-month {
  color: #939393;
}

.calendar-date .react-datepicker__day--selected {
  border-radius: 0;
  background-color: #5181b8;
}

.calendar-date .react-datepicker__day--selected:hover {
  background-color: #5181b8;
}

.calendar-date .react-datepicker__day--keyboard-selected {
  border-radius: 0;
  color: #222222;
  background-color: #fff;
}

.calendar-date .react-datepicker__day--today {
  line-height: 38px;
  border: 1px solid rgba(81, 129, 184, 0.48);
  background-color: #f5f7fa;
  font-weight: normal;
  box-sizing: border-box;
  color: #222222;
}
