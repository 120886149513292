.ModalOwner {
    position: absolute;
    background-color: rgba(237, 238, 240, 0.6);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;
    animation: showModal 1s;
}

.Modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    box-shadow: #bbbbbb 0px 7px 29px 0px;
    // filter: drop-shadow(0 4px 40px rgba(#000, .2));

    &__content {
        > div:last-child {
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
        }
    }

    &__download-icon {
        max-width: 450px;
        width: 100%;

        button {
            margin-top: 21px;
            margin-left: auto;
            margin-right: auto;
            display: block;
        }
    }

    &__feedback {
        max-width: 510px;
        width: 100%;
    }

    &__about {
        max-width: 510px;
        width: 100%;

        .Modal__body {
            max-height: 450px;
            overflow-y: scroll;
            overflow-x: hidden;
        }
    }

    &__close {
        background: url('../img/icons/close.png') no-repeat;
        cursor: pointer;
        width: 15px;
        height: 22px;
        background-size: contain;
        position: absolute;
        right: 25px;
    }

    &__header {
        background-color: #5b88bd;
        color: #fff;
        font-size: 14px;
        letter-spacing: -0.2px;
        padding: 19px 25px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__body {
        padding: 26px 25px;
        background-color: #fff;
        max-height: calc(100vh - 300px);
        overflow-y: scroll;

        p {
            font-size: 13px;
            line-height: 1.38;
            letter-spacing: -0.1px;
            text-align: center;
            color: #000000;
            margin: 0;
        }
    }

    .btn {
        font-size: 12.5px;
        color: #fff;
        text-align: center;
        padding: 7px 16px 8px 16px;
        border-radius: 4px;
        background-color: #5181b8;
        max-width: 120px;
        box-sizing: border-box;
        cursor: pointer;
    }

    &__footer {
        border-top: 1px solid #e7e8ec;
        background-color: #f7f8fa;
        padding: 14px 25px;
        display: flex;
        justify-content: flex-end;

        > button + button {
            margin-left: 8px;
        }
    }
}

.Quote {

    & + .Quote {
        margin-top: 30px;
    }

    &__title {
        font-size: 13px;
        line-height: 1.38;
        color: #000000;
        font-weight: 600;
        text-align: left;
        margin-bottom: 5px;
    }

    &__text {
        font-size: 13px;
        line-height: 1.38;
        color: #000000;
        text-align: left;
    }
}

@keyframes showModal {

    from {
        opacity: 0;
        transition: opacity 0s;
    }

    40%, to {
        opacity: 1;
        transition: opacity 0s;
    }
}