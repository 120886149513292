.Button {
  border-radius: 4px;
  background-color: #5181b8;
  padding: 7px 16px 8px 16px;
  font-size: 12.5px;
  color: #ffffff;
  border: none;
  cursor: pointer;
}

.Button:focus {
  outline: none;
}

.Button:hover {
  opacity: 0.9;
}

.Button--transparent {
  background-color: rgba(255, 255, 255, 0.22);
}

.Button--blank {
  color: #2a5885;
  background-color: transparent;
}

.Button--blank:hover {
  opacity: 0.8;
}

.Button--light {
  background-color: #e5ebf1;
  color: #55677d;
}

.Button--rounded {
  border-radius: 15px;
}

.Button.w-100 {
  width: 100%;
}
