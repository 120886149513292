.toast {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #fff;
  border-bottom: 1px solid #E7E8EC;
  text-align: center;
  color: #828282;
  font-size: 14px;
  cursor: pointer;
  animation: showToast .15s;
  animation-fill-mode: forwards;
  z-index: 2;

  &__inner {
    padding: 20px;
  }
}

@keyframes showToast {
  from {
    top: -56px;
  }
  to {
    top: 0;
  }
}