.Breadcumbs {
  padding: 25px 25px 5px;
  display: flex;
}

.Breadcumbs_left {
  font-size: 16px;
  letter-spacing: -0.32px;
  color: #656565;
  cursor: pointer;
}

.Breadcumbs_left:hover {
  opacity: 0.8;
  text-decoration: underline;
}

.Breadcumbs_arrow {
  width: 6px;
  height: 8px;
  background: url("../img/icons/chevron_16_gray.svg") no-repeat;
  background-size: contain;
  margin-top: 7px;
  margin-left: 10px;
  margin-right: 8px;
}

.Breadcumbs_right {
  font-size: 16px;
  letter-spacing: -0.32px;
  color: #000000;
}
