.Block-main_header {
  background-color: #fafbfc;
  box-shadow: 0 1px 0 0 #e7e8ec;
  padding: 25px;
  display: flex;
  z-index: 1;
  position: relative;
}

.Block-main_header_left_title {
  width: 310px;
  height: 26px;
  font-size: 26px;
  font-weight: 600;
  color: #2c2d2e;
  margin-bottom: 16px;
}

.Block-main_header_left_content {
  font-size: 13px;
  line-height: 1.46;
  letter-spacing: -0.08px;
  color: #000000;
  margin-bottom: 16px;
}

.Block-main_header_left_link {
  font-size: 13px;
  font-weight: 500;
  line-height: 1.46;
  letter-spacing: -0.08px;
  color: #2a5885;
  cursor: pointer;
  position: relative;
  padding-right: 15px;
  display: inline-block;
}

.Block-main_header_left_link:after {
  content: '';
  position: absolute;
  background-image: url("../img/icons/chevron_16_blue.svg");
  height: 10px;
  width: 7px;
  right: 1px;
  top: 4px;
  opacity: 0.5;
}

.Block-main_header_left_link:hover {
  opacity: 0.9;
}

.Block-main_header_right {
  display: flex;
  align-items: center;
}

.Block-main_header_right_bg {
  width: 215px;
  height: 215px;
  background: url("../img/main-bg.png") no-repeat;
  background-size: contain;
  margin-top: -20px;
  margin-left: 20px;
  margin-bottom: -20px;
}

.Block-main_middler {
  display: flex;
  padding: 25px 25px 40px;
  margin-top: -35px;
  flex-wrap: wrap;
  background-color: #fff;
  z-index: 0;
  position: relative;
}

.Block-main_middler .Menu-item {
  width: calc(33.3333% - 25px);
  margin-right: 12.5px;
  margin-left: 12.5px;
  margin-top: 55px;
  cursor: pointer;
}

.Block-main_middler .Menu-item:hover {
  opacity: 0.8;
}

.Block-main_middler .Menu-item_icon {
  width: 52px;
  height: 52px;
  border-radius: 52px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 14px;
  position: relative;
}

.Block-main_middler .Menu-item_icon:after {
  content: '';
  width: 22px;
  height: 22px;
  position: absolute;
  background-repeat: no-repeat;
  background-position: center;
  left: 15px;
  top: 15px;
}

.Block-main_middler .Menu-item_icon--link {
  background-image: linear-gradient(135deg, #9cc8ff, #6f8aec);
}

.Block-main_middler .Menu-item_icon--link:after {
  background-image: url("../img/icons/main/link.svg");
}

.Block-main_middler .Menu-item_icon--wifi {
  background-image: linear-gradient(135deg, #d1a7ff, #8558d8);
}

.Block-main_middler .Menu-item_icon--wifi:after {
  background-image: url("../img/icons/main/wifi.svg");
}

.Block-main_middler .Menu-item_icon--contact {
  background-image: linear-gradient(135deg, #f9b47b, #ed786b);
}

.Block-main_middler .Menu-item_icon--contact:after {
  background-image: url("../img/icons/main/card.svg");
}

.Block-main_middler .Menu-item_icon--text {
  background-image: linear-gradient(135deg, #fdd89b, #f39e0f);
}

.Block-main_middler .Menu-item_icon--text:after {
  background-image: url("../img/icons/main/text.svg");
}

.Block-main_middler .Menu-item_icon--event {
  background-image: linear-gradient(135deg, #ffa2c1, #e85283);
}

.Block-main_middler .Menu-item_icon--event:after {
  background-image: url("../img/icons/main/event.svg");
}

.Block-main_middler .Menu-item_icon--geo {
  background-image: linear-gradient(135deg, #a0e4a0, #5fb25f);
}

.Block-main_middler .Menu-item_icon--geo:after {
  background-image: url("../img/icons/main/geo.svg");
}

.Block-main_middler .Menu-item_title {
  font-size: 17px;
  font-weight: 500;
  letter-spacing: -0.41px;
  text-align: center;
  color: #222222;
  margin-bottom: 9px;
}

.Block-main_middler .Menu-item_desc {
  font-size: 13px;
  line-height: 1.31;
  letter-spacing: -0.08px;
  text-align: center;
  color: #828282;
}
